@tailwind base;
@tailwind components;

:root {
  --primary: #3b82f6;
  --secondary: #fff;
}
/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--secondary) var(--primary);
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

*::-webkit-scrollbar-track {
  background: var(--primary);
  border-radius: 0px;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--secondary);
  border-radius: 0px;
  border: 0px solid var(--primary);
}

.loader-comet {
  position: relative;
}

.comet {
  animation: orbit 1.5s linear infinite;
}

@keyframes orbit {
  0% {
    transform: rotate(0deg) translateX(50px) rotate(0deg);
  }
  100% {
    transform: rotate(360deg) translateX(50px) rotate(-360deg);
  }
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 1s ease-in-out;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.fade {
  opacity: 0;
}

.visible {
  opacity: 1;
}

.scrolling-container {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  padding: 8px;
}

.scrolling-text {
  display: inline-block;
  padding-left: 100%;
  animation: scroll-text 25s linear infinite;
  color: #3b82f6;
  font-size: 0.75rem;
}

@keyframes scroll-text {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}


@tailwind utilities;